@media (min-width: 768px) {
  .AddUserForm .ui.form .fields > .field:first-child {
    padding-left: -0.5em !important;
    margin-left: 0 !important;
  }
  .AddUserForm div.field.userType > label {
    padding-left: 0.5em !important;
    margin-left: 0.5em !important;
  }
  .AddUserForm .FormCompanyType {
    width: 100%;
    padding: 0;
  }
  .AddUserForm .FormCompanyType .company .ui.search.dropdown {
    width: 100%;
    margin: 0em -0.5em 0em 0em;
  }
  .AddUserForm .FormCompanyType .userType .ui.search.dropdown {
    width: 100% !important;
    margin: 0 0 0 1em !important;
  }
}
@media (max-width: 768px) {
  .AddUserForm .ui.form .fields > .field {
    margin: 0 !important;
    padding: 0.5em 0 !important;
  }
  .AddUserForm .ui.selection.dropdown {
    margin: 0.5em 0 !important;
  }
  .AddUserForm .ui.form .fields {
    margin: 0 !important;
  }
}
