.AudioHunter {
  display: flex !important;
  align-items: flex-start !important;
}
.AudioHunter .checkbox {
  display: block;
}
.AudioHunter .inline-block {
  display: inline-block;
}
.AudioHunter .block {
  display: block;
}
.AudioHunter div {
  padding: 10px;
}
.AudioHunter textarea {
  width: 500px;
}
.AudioHunter .noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  background-color: rgba(0, 0, 0, 0.1);
}
.AudioHunter .selected-column {
  text-decoration: underline;
}
