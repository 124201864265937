.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.LinguistReportModal .header {
  text-align: center !important;
  font-weight: 700 !important;
  background-color: #FDD302 !important;
  color: #333333 !important;
  border-bottom: none !important;
}
.LinguistReportModal .not-submitted {
  color: #F44335 !important;
  border-color: #F44335 !important;
}
.LinguistReportModal .pending {
  color: #F44335 !important;
  border-color: #F44335 !important;
}
.LinguistReportModal .accepted {
  color: #65A838 !important;
  border-color: #65A838 !important;
}
.LinguistReportModal .other-tasks {
  width: 100%;
}
.LinguistReportModal .add-button {
  background-color: #FDD302 !important;
  color: #333333 !important;
}
.LinguistReportModal .close-button {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 18px;
}
.LinguistReportModal .horizontal-center {
  margin-top: auto;
  margin-bottom: auto;
}
.LinguistReportModal .item-centered {
  padding-bottom: unset !important;
  padding-top: unset !important;
  margin-top: auto;
  margin-bottom: auto;
}
.LinguistReportModal .date-margin {
  padding-left: 2em !important;
}
.LinguistReportModal .status-label {
  border: none !important;
  margin: unset !important;
  padding: none none none 1em !important;
  animation: text-fade 0.4s ease-in;
}
.LinguistReportModal .error-column {
  animation: text-fade 0.4s ease-in;
  max-height: 3em;
  padding-top: unset !important;
  padding-bottom: unset !important;
  margin-bottom: 1em !important;
  color: #F44335;
}
.LinguistReportModal .last-grid {
  margin-bottom: 1em !important;
}
.LinguistReportModal .fixed-table {
  table-layout: fixed;
}
.LinguistReportModal .fixed-table th {
  font-size: 11px;
}
@keyframes text-fade {
  from {
    color: transparent;
  }
  to {
    color: auto;
  }
}
