.tabs-menu {
  padding: 0.8em 0;
  width: 100%;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid #d4d4d5;
  margin-top: 0.5em;
}
.tabs-menu .ui.red.label {
  line-height: 0.5;
  font-size: 0.8em;
  margin-left: 0.7em;
}
.tab-panel {
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  border-top: none;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
  padding: 1.5em;
}
.tab-panel.sub-tab {
  padding-top: 3em;
}
.tab-panel.sub-tab li {
  margin-left: 20px;
}
.tab-panel .header .answers {
  margin-bottom: 2em;
}
.tab-panel .ui.button {
  margin: 5px 2px;
}
.tab-panel .ui.centered.grid.Footer {
  margin: 30px -1.5em -1.5em;
}
.tab-panel .ui.grid > .header {
  padding-bottom: 0;
}
ui.header {
  padding-bottom: 10px !important;
}
.ui.label.modified {
  position: relative;
  z-index: 10;
  margin: 0.5em;
}
