.ImageSegmentationWidgetEditor .ui.radio.checkbox {
  margin: 0 2% !important;
}
.ImageSegmentationWidgetEditor .segmentTypes {
  width: 70%;
  margin: auto !important;
}
.ImageSegmentationWidgetEditor .segmentTypes .row {
  align-items: center !important;
}
.ImageSegmentationWidgetEditor .segmentTypes .column {
  padding: 0 1% !important;
  height: 100%;
}
.ImageSegmentationWidgetEditor .segmentTypes .column .ui.mini.input input {
  text-align: center !important;
  width: 5em;
}
.ImageSegmentationWidgetEditor .segmentTypes .column .ui.fitted.toggle.checkbox {
  float: right;
  transform: translateY(-25%);
}
.ImageSegmentationWidgetEditor .segmentTypes .column .ui.fitted.toggle.checkbox::before {
  content: 'Infinite';
  position: relative;
  top: -5px;
}
