.recorder-main {
  display: flex;
}
.recorder-main .audio-container .recorder-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.recorder-main .audio-container .recorder-body button {
  margin: auto;
}
.recorder-main .audio-container .recorder-body span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recorder-main .audio-container .recorder-body span.status {
  margin-left: 0.5rem;
  margin-right: -0.5rem;
}
.recorder-main .audio-container .recorder-body span .playing {
  -webkit-animation: record 2s ease-in alternate infinite;
  -moz-animation: record 2s ease-in alternate infinite;
  animation: record 2s ease-in alternate infinite;
}
.recorder-main .audio-container .recorder-body .recorder-controls {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  background: #e8e8e8;
  padding: 0.6rem 1rem;
  border-radius: 30px;
}
.recorder-main .audio-container .recorder-body .audio-answer-timer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: -0.5rem;
}
.recorder-main .audio-container .recorder-body .audio-answer-timer span {
  display: flex;
  width: 1rem;
  justify-content: center;
  align-items: start;
  border-radius: 6px;
  padding: 0 0.8rem 0;
  font-weight: bold;
}
.recorder-main .audio-container .recorder-body .audio-answer-timer span:after {
  content: 'h';
  position: absolute;
  margin-top: 1rem;
  font-size: 0.7rem;
  font-weight: 600;
  color: #5c5c5c;
}
.recorder-main .audio-container .recorder-body .audio-answer-timer span:nth-child(2):after {
  content: "m";
}
.recorder-main .audio-container .recorder-body .audio-answer-timer span:nth-child(3):after {
  content: "s";
}
.recorder-main .audio-container .recorder-body .audio-answer-timer span:nth-child(4) {
  font-size: 0.8rem;
  color: #5c5c5c;
}
.recorder-main .audio-container .recorder-body .audio-answer-timer span:nth-child(4):after {
  content: "ms";
}
@keyframes record {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
