.admin-bottom-bar {
  background-color: #fff;
  padding: 4px;
}
@media only screen and (max-width: 767px) {
  .admin-bottom-bar .bottom-bar-save-another {
    display: none !important;
  }
}
.admin-bottom-bar .bottom-right {
  margin-left: 5px;
}
