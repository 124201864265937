.FileUploadWidget {
  text-align: center;
}
.FileUploadWidget .dropzone-container div {
  text-align: center;
  width: auto !important;
  min-height: 100px;
  height: inherit !important;
  margin-left: 10px;
  margin-right: 10px;
}
.FileUploadWidget ul {
  text-align: left;
}
.FileUploadWidget .button {
  margin-top: 5px;
  margin-bottom: 5px;
}
.FileUploadWidget .files {
  text-align: center;
}
.FileUploadWidget .files img {
  max-width: 600px;
  max-height: 600px;
}
