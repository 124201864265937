.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.filters-menu {
  margin: 0 !important;
  max-width: 360px;
}
.filters-menu .filters-form {
  padding: 14px;
  min-width: 50%;
}
.filters-menu .filters-form button.ui.basic.button {
  background-color: #51f5ff;
  margin-top: 15px;
}
.filters-menu .filters-form .field > .selection.dropdown > a.ui.label {
  max-width: 100%;
}
.filters-menu .advanced-button button {
  width: 80% !important;
  margin: 3px 10%;
  position: relative;
}
.filters-menu .advanced-button button .advanced-filter-label {
  margin: -8px -15px;
  background-color: #034EA2;
  color: #fff;
  min-width: 50px;
}
.filters-menu .filters-button {
  text-align: center;
}
