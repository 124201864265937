.widget-id-container {
  position: absolute;
  right: 0;
  border-radius: 3px;
  background-color: #FF73FD;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 0 !important;
  z-index: 999;
}
.widget-id-container.left {
  right: initial;
  left: 0;
  transform: none;
}
.widget-id-copy-checkbox {
  margin: 0.1rem 0.2rem 0.1rem 0.3rem;
}
