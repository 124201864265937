.ContainerDetail {
  padding-bottom: 35px;
}
.ContainerDetail .modal .ui.grid {
  justify-content: center;
}
.ContainerDetail .modal .close-button {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 18px;
}
.ContainerDetail .modal .ui.dropdown {
  padding: 7px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.ContainerDetail .ui.dropdown {
  padding: 7px;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.ContainerDetail .DragAndDropSelectorGroup {
  width: 100%;
}
.ContainerDetail .field {
  padding-bottom: 5px;
}
