.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
div.container {
  display: flex;
  justify-content: center;
}
div.container .users-permissions-list {
  width: 65%;
}
@media screen and (max-width: 1538px) {
  div.container .users-permissions-list {
    width: 100%;
  }
}
div.container .users-permissions-list div.ui.fitted.toggle.checkbox::after {
  display: none;
}
div.container .users-permissions-list td {
  overflow: visible !important;
}
div.container .users-permissions-list td a {
  color: #858585;
}
div.container .users-permissions-list .export-segment {
  max-width: 250px;
}
.filter-popup .filter-menu div.field {
  min-width: 200px;
}
