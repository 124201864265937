.ImageSegmentationWidget div.segment-rectangle {
  position: absolute;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: greenyellow;
  outline: 1px solid black;
  color: greenyellow;
  text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black;
}
