.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
@font-face {
  font-family: 'samsungsharpsans_bold';
  src: url('/langtasks/fonts/samsungsharpsans_bold.ttf');
}
@font-face {
  font-family: 'samsungone400';
  src: url('/langtasks/fonts/samsungone_400.ttf');
}
@font-face {
  font-family: 'samsungone700';
  src: url('/langtasks/fonts/samsungone_700.ttf');
}
@font-face {
  font-family: 'samsungone800';
  src: url('/langtasks/fonts/samsungone_800.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin200';
  src: url('/langtasks/fonts/samsungoneitaliclatin_200.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin900';
  src: url('/langtasks/fonts/samsungoneitaliclatin_900.ttf');
}
@font-face {
  font-family: 'samsungonekorean200';
  src: url('/langtasks/fonts/samsungonekorean_otf_200.ttf');
}
@font-face {
  font-family: 'samsungonekorean300';
  src: url('/langtasks/fonts/samsungonekorean_otf_300.ttf');
}
@font-face {
  font-family: 'samsungonekorean400';
  src: url('/langtasks/fonts/samsungonekorean_otf_400.ttf');
}
@font-face {
  font-family: 'samsungonekorean500';
  src: url('/langtasks/fonts/samsungonekorean_otf_500.ttf');
}
@font-face {
  font-family: 'samsungonekorean600';
  src: url('/langtasks/fonts/samsungonekorean_otf_600.ttf');
}
@font-face {
  font-family: 'samsungonekorean700';
  src: url('/langtasks/fonts/samsungonekorean_otf_700.ttf');
}
.lander {
  text-align: center;
}
.lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.lander p {
  color: #050505;
}
.gradient {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.green-radio input {
  border: none;
}
.green-radio label::before {
  border-radius: 50% !important;
}
.green-radio label::after {
  background-color: #21ba45;
  border-radius: 50%;
  font-size: 0px !important;
  position: absolute !important;
  top: 3px !important;
  left: 3px !important;
  width: 11px !important;
  height: 11px !important;
}
.FM_SO_400 {
  font-family: 'samsungone400';
}
.FM_SO_700 {
  font-family: 'samsungone700';
}
.FM_SO_800 {
  font-family: 'samsungone800';
}
.FM_IL_200 {
  font-family: 'samsungone_italic_latin200';
}
.FM_IL_900 {
  font-family: 'samsungone_italic_latin900';
}
.FM_SHARP_BOLD {
  font-family: 'samsungsharpsans_bold';
}
.FM_KR_200 {
  font-family: 'samsungonekorean200';
}
.FM_KR_300 {
  font-family: 'samsungonekorean300';
}
.FM_KR_400 {
  font-family: 'samsungonekorean400';
}
.FM_KR_500 {
  font-family: 'samsungonekorean500';
}
.FM_KR_600 {
  font-family: 'samsungonekorean600';
}
.FM_KR_700 {
  font-family: 'samsungonekorean700';
}
.TaskAdminDetail .Detail form {
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: repeat(minmax(150px, 1fr));
}
.TaskAdminDetail .Detail form div {
  display: block;
  width: 100%;
}
.TaskAdminDetail .Detail form div .ui.input > input,
.TaskAdminDetail .Detail form div .ui.dropdown {
  width: 100% !important;
  height: 40px !important;
}
.TaskAdminDetail .Detail form div label {
  padding: 0px !important;
  margin: 0px !important;
}
.TaskAdminDetail .Detail .title,
.TaskAdminDetail .Detail .module,
.TaskAdminDetail .Detail .language {
  grid-column: auto / span 2;
}
.TaskAdminDetail .Detail .questions-answers,
.TaskAdminDetail .Detail .types {
  grid-column: auto / span 8;
  display: flex;
}
.TaskAdminDetail .Detail .questions-answers > div,
.TaskAdminDetail .Detail .types > div {
  margin-right: 0.5em;
}
.TaskAdminDetail .Detail .questions-answers > div:last-child,
.TaskAdminDetail .Detail .types > div:last-child {
  margin-right: 0;
}
.TaskAdminDetail .Detail .questions_at_page,
.TaskAdminDetail .Detail .max_answers {
  max-width: 8em;
}
.TaskAdminDetail .Detail .possible_empty_answers,
.TaskAdminDetail .Detail .possible_review,
.TaskAdminDetail .Detail .can_linguist_start_discussion,
.TaskAdminDetail .Detail .enable_evaluations,
.TaskAdminDetail .Detail .enable_search_evaluations {
  align-self: center !important;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
  text-align: center;
  text-transform: capitalize;
}
.TaskAdminDetail .Detail .possible_empty_answers label,
.TaskAdminDetail .Detail .possible_review label,
.TaskAdminDetail .Detail .can_linguist_start_discussion label,
.TaskAdminDetail .Detail .enable_evaluations label,
.TaskAdminDetail .Detail .enable_search_evaluations label {
  display: inline;
  padding-left: 25px !important;
}
.TaskAdminDetail .Detail .possible_empty_answers input,
.TaskAdminDetail .Detail .possible_review input,
.TaskAdminDetail .Detail .can_linguist_start_discussion input,
.TaskAdminDetail .Detail .enable_evaluations input,
.TaskAdminDetail .Detail .enable_search_evaluations input {
  border: none;
}
.TaskAdminDetail .Detail .possible_empty_answers label::before,
.TaskAdminDetail .Detail .possible_review label::before,
.TaskAdminDetail .Detail .can_linguist_start_discussion label::before,
.TaskAdminDetail .Detail .enable_evaluations label::before,
.TaskAdminDetail .Detail .enable_search_evaluations label::before {
  border-radius: 50% !important;
}
.TaskAdminDetail .Detail .possible_empty_answers label::after,
.TaskAdminDetail .Detail .possible_review label::after,
.TaskAdminDetail .Detail .can_linguist_start_discussion label::after,
.TaskAdminDetail .Detail .enable_evaluations label::after,
.TaskAdminDetail .Detail .enable_search_evaluations label::after {
  background-color: #21ba45;
  border-radius: 50%;
  font-size: 0px !important;
  position: absolute !important;
  top: 3px !important;
  left: 3px !important;
  width: 11px !important;
  height: 11px !important;
}
.TaskAdminDetail .Detail .resizer {
  grid-column: 1 / span 8;
}
@media only screen and (max-width: 933px) {
  .TaskAdminDetail .Detail .resizer {
    grid-column: 1 / span 2;
  }
}
.TaskAdminDetail .form-bottom {
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: repeat(minmax(150px, 1fr));
}
.TaskAdminDetail .form-bottom div {
  display: block;
  width: 100%;
}
.TaskAdminDetail .form-bottom div .ui.input > input,
.TaskAdminDetail .form-bottom div .ui.dropdown {
  width: 100% !important;
  height: 40px !important;
}
.TaskAdminDetail .form-bottom div label {
  padding: 0px !important;
  margin: 0px !important;
}
.TaskAdminDetail .form-bottom .moderators {
  grid-column: 1 / span 4;
}
.TaskAdminDetail .form-bottom .moderators li {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
}
@media only screen and (max-width: 933px) {
  .TaskAdminDetail .form-bottom .moderators {
    grid-column: 1 / span 2;
  }
}
.TaskAdminDetail .disabled.field {
  opacity: 1 !important;
}
.TaskAdminDetail .disabled.field input {
  border-color: transparent !important;
}
.TaskAdminDetail .field.disabled > label {
  opacity: 1 !important;
}
.TaskAdminDetail .ui.grid.drag-and-drop-selector .const-height {
  height: 300px;
}
.TaskAdminDetail label.field-label {
  margin-top: 5px;
}
.TaskAdminDetail .ui.input > *,
.TaskAdminDetail .ui.selection.dropdown > *,
.TaskAdminDetail .ui.selection.dropdown {
  padding: 0.4em 0.7em !important;
}
.TaskAdminDetail .ui.input > * div.text,
.TaskAdminDetail .ui.selection.dropdown > * div.text,
.TaskAdminDetail .ui.selection.dropdown div.text {
  padding-left: 0 !important;
}
.TaskAdminDetail .ui.table.compact.UserTasks {
  font-size: 0.8em;
}
.TaskAdminDetail .ui.input.narrow {
  max-width: 60px;
}
.TaskAdminDetail .ui.input.limit {
  width: 5.5em;
}
.TaskAdminDetail .Flexbox {
  justify-content: space-between;
}
.TaskAdminDetail .ui.form .fields .field .ui.checkbox {
  margin-top: 8px;
}
.TaskAdminDetail .ui.form .fields .narrow-field.inline .ui.input {
  width: 4em;
}
.TaskAdminDetail .ui.form .fields .narrower-field.inline .ui.disabled.input {
  width: 5em;
}
.TaskAdminDetail .ui.form .descError {
  color: #9f3a38;
  text-align: center;
  margin-bottom: 0.7em;
  font-weight: 700;
}
.TaskAdminDetail .ui.checkbox.change-tracked-checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
}
.TaskAdminDetail .ui.checkbox.change-tracked-checkbox label {
  font-size: 0.8em;
}
.TaskAdminDetail .narrow.username-dropdown .visible.menu.transition {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.TaskAdminDetail .narrow.username-dropdown .visible.menu.transition .item {
  padding-left: 2px !important;
  font-size: 1em !important;
}
.TaskAdminDetail .tab-list-active {
  background-color: rgba(200, 200, 200, 0.5);
}
.TaskAdminDetail .tab-list-active:after {
  height: 3px;
}
