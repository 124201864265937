.Movable .dragged {
  opacity: 0.1;
}
.Movable .dragover {
  background-color: #fcd1bb;
}
.Movable :hover,
.Movable.hover {
  background-color: #f1f1fc;
}
.Movable .Movable :hover,
.Movable .Movable.hover {
  background-color: #dce2fc;
}
.Movable .Movable .Movable :hover,
.Movable .Movable .Movable.hover {
  background-color: #bdd3fc;
}
.Movable .ContainerWidget {
  padding-top: 25px !important;
  padding-bottom: 0px !important;
}
