.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.ImageSegmentationWidget {
  text-align: center;
}
.ImageSegmentationWidget div.image-container {
  z-index: 999;
  position: relative;
  display: inline-block;
}
.ImageSegmentationWidget .react-contextmenu-wrapper {
  width: max-content;
  margin: auto;
}
.ImageSegmentationWidget nav.react-contextmenu {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
}
.ImageSegmentationWidget nav.react-contextmenu .react-contextmenu-item {
  padding: 8px 8px 8px 8px;
}
.ImageSegmentationWidget nav.react-contextmenu .react-contextmenu-item--selected {
  background-color: #034EA2;
  color: white;
}
