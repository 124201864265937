.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.QuestionsSet .Question:not(:last-child) {
  margin-bottom: 2.5rem !important;
  border-bottom: solid silver 1px;
}
.QuestionsSet .header > .button,
.QuestionsSet .answers > .button {
  padding: 0.5rem 1rem !important;
  font-size: 11px !important;
}
.QuestionsSet .header {
  margin: 0 !important;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
}
.QuestionsSet .header .answers {
  padding: 0 0.5rem !important;
}
.QuestionsSet .header .answers .clipboard.icon {
  padding-left: 0.3rem;
}
.QuestionsSet .header .active-answer-timestamp {
  max-width: max-content;
  padding: 0.5rem 1.5rem !important;
}
.QuestionsSet .header .question-id {
  max-width: max-content;
  max-height: 2rem;
  padding: 0.5rem 1.5rem !important;
}
.QuestionsSet .ContainerWidget {
  border: none !important;
}
.QuestionsSet .wrapper {
  position: relative;
}
.QuestionsSet .wrapper .question.non-submit {
  margin-bottom: 2.5rem !important;
}
.QuestionsSet .wrapper .question.evaluation:first-child {
  margin-top: 2.5rem !important;
}
.QuestionsSet .wrapper .answered {
  pointer-events: none;
}
.QuestionsSet .wrapper .overlay {
  background-color: rgba(0, 255, 0, 0.1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  pointer-events: none;
  border-radius: 10px;
}
.QuestionsSet .wrapper .overlay .header {
  margin: auto auto 1rem;
}
.QuestionsSet .activeAnswerTimestampFormatted {
  font-size: 9px;
}
.popup .copyHashCode {
  color: #2185D0;
  cursor: pointer;
}
div.eval-question {
  max-height: 400px;
  overflow-y: scroll;
}
div.ui.fluid.card.card-question {
  color: #858585 !important;
  background-color: #F7F7F7;
  padding: 5px;
  box-shadow: none;
  pointer-events: none;
  opacity: 0.9;
}
.eval-answers-label .ui.label.active-answer-timestamp {
  float: right !important;
}
