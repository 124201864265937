.UPEUList th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.UPEUList .ui.disabled.segment {
  border: transparent;
  margin: 0 auto;
  box-shadow: none;
}
.UPEUList .upeu-header .ui.checkbox {
  margin-left: 5px;
}
.UPEUList .ui.fluid.search.selection.dropdown {
  min-width: 320px;
}
.UPEUList .ui.table {
  font-size: 0.8em;
}
.UPEUList .ui.table label {
  font-size: 0.8em;
}
