.SubmitButtonWidgetEditor .row {
  padding: 10px !important;
  margin: 0px !important;
}
.SubmitButtonWidgetEditor > div {
  width: 100%;
}
.SubmitButtonWidgetEditor .header {
  margin: auto;
}
