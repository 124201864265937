.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.TaskDetail .filtersOption {
  margin: 0 10px;
}
.TaskDetail .ui.table {
  border-color: #D8D8D8 !important;
  margin-top: 25px !important;
}
.TaskDetail .detailsPanel td {
  border: 0 !important;
}
.TaskDetail .detailsPanel th {
  background-color: #EBEBEB !important;
  border-color: #D8D8D8 !important;
  color: #333333 !important;
}
