.TextareaWidgetEditor {
  user-select: none !important;
}
.TextareaWidgetEditor .row {
  padding: 5px !important;
  margin: 0px !important;
}
.TextareaWidgetEditor .label {
  display: flex;
}
.TextareaWidgetEditor .label #label-width input {
  width: 100px;
}
