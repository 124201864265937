.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.QuestionDetailAdmin div.jsoneditor-menu {
  background-color: #9c9c9c;
  border-bottom: 1px solid #9c9c9c;
}
.QuestionDetailAdmin div.jsoneditor {
  border: thin solid #9c9c9c;
}
.QuestionDetailAdmin button.ui.basic.button {
  border: none !important;
  box-shadow: 0 0 0 0 transparent !important;
}
.QuestionDetailAdmin button.ui.basic.button:hover {
  border: none !important;
  box-shadow: 0 0 0 0 transparent !important;
}
