.RaportDeadlineList .header {
  display: flex;
  justify-content: space-between;
}
.RaportDeadlineList .header .header-left .filter.icon {
  margin-left: 0.5em !important;
  margin-right: -0.5em !important;
}
.RaportDeadlineList .ui.table.sortable {
  font-size: 0.8em;
}
.RaportDeadlineList .ui.table.sortable th {
  border-left: none;
  border-right: none;
}
@media screen and (max-width: 768px) {
  .RaportDeadlineList .ui.table th,
  .RaportDeadlineList .ui.table td {
    text-align: center;
  }
  .RaportDeadlineList .header .header-left .input input {
    border-radius: 25px !important;
    width: 150px;
  }
}
