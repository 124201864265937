.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.OTPFactorLogin {
  padding-top: 3em;
}
.OTPFactorLogin > .clickableMessage {
  cursor: pointer;
}
.OTPFactorLogin .field.send-btn button.ui.button {
  background-color: #65A838;
  color: #FFFFFF;
}
.OTPFactorLogin .ui.basic.button.reset-btn {
  box-shadow: 0 0 0 1px #F44335 inset !important;
  color: #F44335 !important;
}
.ui.form .ui.header {
  color: #333333;
}
.otp-disclaimer-modal .modal-content {
  padding: 20px;
  white-space: pre-wrap;
}
.otp-disclaimer-modal .small-indent {
  text-indent: 20px;
}
.otp-disclaimer-modal .big-indent {
  text-indent: 40px;
  white-space: pre-wrap;
}
.ui.modal.transition.visible.active.auth-choice-modal {
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 30%;
}
.ui.modal.transition.visible.active.auth-choice-modal .auth-choice-buttons {
  padding: 50px;
  display: flex;
  flex-direction: column;
}
