.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.password-change h2 {
  color: #333333 !important;
  margin: calc(2rem - 0.14285714em) 0 1rem !important;
}
.password-change .preload .item {
  animation-duration: 0s !important;
}
.password-change .change-grid {
  text-align: left;
}
.password-change .change-grid .checked-errors {
  margin-top: 1.2rem;
}
.password-change .change-grid .checked-errors .bold-requirement {
  font-weight: bold;
}
.password-change .change-grid .checked-errors .item {
  padding-bottom: 0.44em !important;
  padding-top: 0 !important;
}
.password-change .change-grid .checked-errors .checked-requirement {
  color: #03B591;
  text-decoration: line-through;
  animation: strike-requirement 0.4s ease, color-diffusion 0.6s ease;
}
.password-change .change-grid .checked-errors .unchecked-requirement {
  color: auto;
  text-decoration: line-through;
  text-decoration-color: transparent;
  animation: strike-requirement2 0.2s ease-in, color-diffusion2 1s ease;
}
@keyframes strike-requirement {
  from {
    text-decoration-color: transparent;
  }
  to {
    text-decoration-color: auto;
  }
}
@keyframes color-diffusion {
  from {
    color: auto;
  }
  to {
    color: #03B591;
  }
}
@keyframes strike-requirement2 {
  from {
    text-decoration-color: #03B591;
  }
  to {
    text-decoration-color: transparent;
  }
}
@keyframes color-diffusion2 {
  from {
    color: #03B591;
  }
  to {
    color: auto;
  }
}
