.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.TicketAdminList .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TicketAdminList .header-filters .input {
  margin-right: 1em;
}
.TicketAdminList .header-filters .input input {
  border-radius: 25px !important;
  width: 400px;
}
.TicketAdminList .header-filters .filter.icon {
  margin-left: 0.5em !important;
  margin-right: -0.5em !important;
}
.TicketAdminList .header-filters span.reset-filters-button {
  color: #A88600;
  cursor: pointer;
}
.TicketAdminList .ui.table.sortable {
  font-size: 0.8em;
}
.TicketAdminList .ui.table.sortable th {
  border-left: none;
  border-right: none;
}
@media screen and (max-width: 768px) {
  .TicketAdminList .ui.table th,
  .TicketAdminList .ui.table td {
    text-align: center;
  }
  .TicketAdminList .header .header-filters .input input {
    border-radius: 25px !important;
    width: 150px;
  }
}
.TicketAdminList .ui.celled.unstackable.center.aligned.table tbody tr td:nth-child(6),
.TicketAdminList .ui.celled.unstackable.center.aligned.table tbody tr td:nth-child(7) {
  color: #F44335 !important;
}
.TicketAdminList .ui.celled.unstackable.center.aligned.table tbody tr td:nth-child(6).disabled,
.TicketAdminList .ui.celled.unstackable.center.aligned.table tbody tr td:nth-child(7).disabled {
  color: #fcd3d0 !important;
}
.TicketAdminList .bottom-bar-displayed {
  margin-bottom: 45px;
}
