.AudioAnswerWidget {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.AudioAnswerWidget .ui.progress {
  width: 100%;
}
.AudioAnswerWidget .dropzone-container div {
  text-align: center;
  width: auto !important;
  min-height: 100px;
  height: inherit !important;
  margin-left: 10px;
  margin-right: 10px;
}
.AudioAnswerWidget ul {
  text-align: left;
}
.AudioAnswerWidget .button {
  margin-top: 5px;
  margin-bottom: 5px;
}
.AudioAnswerWidget .files {
  text-align: center;
}
.AudioAnswerWidget .files img {
  max-width: 600px;
  max-height: 600px;
}
