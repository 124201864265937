.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.Tasks .button {
  color: #333333;
}
.Tasks .button .star {
  color: #FFFFFF !important;
}
.Tasks .ui.yellow.button,
.Tasks .ui.yellow.buttons .button {
  padding: 0.5em !important;
}
.Tasks .header {
  text-align: center;
  background-color: #EBEBEB !important;
}
.Tasks .finished {
  color: #65A838;
}
.Tasks .in-review {
  color: #3F7CAC;
}
.Tasks .in-progress {
  color: #FDD302;
}
.Tasks .not-started {
  color: #dddbcb;
}
.Tasks .title a {
  color: #858585;
}
.Tasks .title a.selected-task-link {
  margin-right: 10px;
}
.Tasks .options button {
  padding: 10px 15px;
}
.Tasks .sub-row {
  border-color: #FBB600;
  border-left-width: 5px !important;
  border-left-style: solid !important;
  padding-bottom: 7px !important;
  padding-top: 7px !important;
}
.Tasks .clear {
  background-color: #FCAC19 !important;
  color: #333333 !important;
}
@media (min-width: 720px) {
  .Tasks .clear {
    width: 80px;
  }
}
.Tasks .aligned-top {
  vertical-align: top;
}
.Tasks .ui.button.favorites {
  background-color: transparent;
}
.Tasks .ui.button.favorites:hover {
  filter: brightness(90%);
}
.Tasks .ui.button.notFavorites {
  background-color: transparent;
}
.Tasks .ui.button.notFavorites:hover {
  filter: brightness(90%);
}
.Tasks .aligned-status {
  vertical-align: middle;
}
.Tasks div.status-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Tasks .icon.handshake {
  margin: 0;
}
.Tasks .icon.handshake.status-blocked {
  color: #FDD302;
}
.Tasks .icon.handshake.status-assigned,
.Tasks .icon.handshake.status-started,
.Tasks .icon.handshake.status-evaluator {
  color: #65A838;
}
.Tasks .icon.handshake.status-resigned {
  color: #F44335;
}
.Tasks .usersList {
  margin-top: 0 !important;
}
.Tasks .headers {
  text-align: center !important;
}
.Tasks span.reset-filters-button {
  color: #A88600;
  cursor: pointer;
}
.Tasks .filter.icon {
  margin-left: 0.5em !important;
  margin-right: -0.5em !important;
}
.popupHeaders {
  text-align: center !important;
}
.ui.table tr.taskListItem:hover:hover {
  background-color: rgba(253, 211, 2, 0.2) !important;
}
.taskListItem .userRowEvaluation {
  position: relative !important;
}
.taskListItem i.icon.datahive-icon.dark-grey.userRowEvaluation {
  position: absolute !important;
}
