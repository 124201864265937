.new-ticket {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 8rem;
  width: 50%;
}
.new-ticket .ui.form {
  width: 100%;
}
