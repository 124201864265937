.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
@font-face {
  font-family: 'samsungsharpsans_bold';
  src: url('/langtasks/fonts/samsungsharpsans_bold.ttf');
}
@font-face {
  font-family: 'samsungone400';
  src: url('/langtasks/fonts/samsungone_400.ttf');
}
@font-face {
  font-family: 'samsungone700';
  src: url('/langtasks/fonts/samsungone_700.ttf');
}
@font-face {
  font-family: 'samsungone800';
  src: url('/langtasks/fonts/samsungone_800.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin200';
  src: url('/langtasks/fonts/samsungoneitaliclatin_200.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin900';
  src: url('/langtasks/fonts/samsungoneitaliclatin_900.ttf');
}
@font-face {
  font-family: 'samsungonekorean200';
  src: url('/langtasks/fonts/samsungonekorean_otf_200.ttf');
}
@font-face {
  font-family: 'samsungonekorean300';
  src: url('/langtasks/fonts/samsungonekorean_otf_300.ttf');
}
@font-face {
  font-family: 'samsungonekorean400';
  src: url('/langtasks/fonts/samsungonekorean_otf_400.ttf');
}
@font-face {
  font-family: 'samsungonekorean500';
  src: url('/langtasks/fonts/samsungonekorean_otf_500.ttf');
}
@font-face {
  font-family: 'samsungonekorean600';
  src: url('/langtasks/fonts/samsungonekorean_otf_600.ttf');
}
@font-face {
  font-family: 'samsungonekorean700';
  src: url('/langtasks/fonts/samsungonekorean_otf_700.ttf');
}
.lander {
  text-align: center;
}
.lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.lander p {
  color: #050505;
}
.gradient {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.green-radio input {
  border: none;
}
.green-radio label::before {
  border-radius: 50% !important;
}
.green-radio label::after {
  background-color: #21ba45;
  border-radius: 50%;
  font-size: 0px !important;
  position: absolute !important;
  top: 3px !important;
  left: 3px !important;
  width: 11px !important;
  height: 11px !important;
}
.FM_SO_400 {
  font-family: 'samsungone400';
}
.FM_SO_700 {
  font-family: 'samsungone700';
}
.FM_SO_800 {
  font-family: 'samsungone800';
}
.FM_IL_200 {
  font-family: 'samsungone_italic_latin200';
}
.FM_IL_900 {
  font-family: 'samsungone_italic_latin900';
}
.FM_SHARP_BOLD {
  font-family: 'samsungsharpsans_bold';
}
.FM_KR_200 {
  font-family: 'samsungonekorean200';
}
.FM_KR_300 {
  font-family: 'samsungonekorean300';
}
.FM_KR_400 {
  font-family: 'samsungonekorean400';
}
.FM_KR_500 {
  font-family: 'samsungonekorean500';
}
.FM_KR_600 {
  font-family: 'samsungonekorean600';
}
.FM_KR_700 {
  font-family: 'samsungonekorean700';
}
.SIOSTRAWidget text {
  white-space: pre;
}
.SIOSTRAWidget .react-draggable {
  z-index: 999;
}
.SIOSTRAWidget .description {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.SIOSTRAWidget .decscriptionsRef {
  width: 100%;
}
.SIOSTRAWidget .close.icon {
  top: 1.0535rem !important;
  right: 1rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.SIOSTRAWidget .ui.compact.icon.button,
.SIOSTRAWidget .ui.compact.icon.buttons .button {
  padding: 0.78571429em 0.78571429em 0.78571429em;
}
.SIOSTRAWidget li.node.tree label input.checkbox-item {
  visibility: collapse;
  height: 0 !important;
  width: 0 !important;
}
.SIOSTRAWidget .node-title.background_Red {
  background-color: #ff1d13 !important;
}
.SIOSTRAWidget .node-title.background_Orange {
  background-color: #f2711c !important;
}
.SIOSTRAWidget .node-title.background_Yellow {
  background-color: #fbbd08 !important;
}
.SIOSTRAWidget .node-title.background_Olive {
  background-color: #b5cc18 !important;
}
.SIOSTRAWidget .node-title.background_Green {
  background-color: #21ba45 !important;
}
.SIOSTRAWidget .node-title.background_Teal {
  background-color: #00b5ad !important;
}
.SIOSTRAWidget .node-title.background_Blue {
  background-color: #2185d0 !important;
}
.SIOSTRAWidget .node-title.background_Violet {
  background-color: #6435c9 !important;
}
.SIOSTRAWidget .node-title.background_Purple {
  background-color: #a333c8 !important;
}
.SIOSTRAWidget .node-title.background_Pink {
  background-color: #e03997 !important;
}
.SIOSTRAWidget .node-title.background_Brown {
  background-color: #a5673f !important;
}
.SIOSTRAWidget .node-title.background_Grey {
  background-color: #767676 !important;
}
.SIOSTRAWidget .node-title.background_Black {
  background-color: #1b1c1d !important;
}
.SIOSTRAWidget .node-title.background_SteelBlue {
  background-color: #224459 !important;
}
.SIOSTRAWidget .node-title.background_Grapefruit {
  background-color: #F2AC29 !important;
}
.SIOSTRAWidget .node-title.background_LightBrown {
  background-color: #A6978F !important;
}
.SIOSTRAWidget .node-title.background_DarkRed {
  background-color: #B6440A !important;
}
.SIOSTRAWidget.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.SIOSTRAWidgetEditor div.jsoneditor-outer {
  height: 500px;
}
