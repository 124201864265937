.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.progress-mini .total {
  background-color: #858585;
}
.progress-mini .done {
  background-color: #FCAC19 !important;
}
.progress-mini .assigned {
  display: flex !important;
  position: absolute !important;
}
.progress-mini .bar {
  min-width: 0px !important;
  max-width: 100%;
  animation: width-change 1.2s ease;
}
.progress-mini .ui.tiny.progress .bar {
  height: 0.72em !important;
}
.progress-mini .progress-middle {
  animation: background-change 0.4s linear;
}
@keyframes width-change {
  from {
    max-width: 0%;
  }
  to {
    max-width: 100%;
  }
}
@keyframes background-change {
  from {
    background: transparent;
  }
  to {
    background: auto;
  }
}
