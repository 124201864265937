.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
div.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
div.main-container div.central-container {
  margin-bottom: 200px;
  min-width: 558px;
  min-height: 267px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
div.main-container div.central-container svg.meh-face-icon {
  width: 127px;
  height: 127px;
  margin-right: -15px;
  color: #FBBD08B8;
}
div.main-container div.central-container div.divider {
  width: 1px;
  height: 154px;
  background: #FCCF4C 0 0 no-repeat padding-box;
}
div.main-container div.central-container div.information-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.main-container div.central-container div.information-container span.error-code {
  width: 265px;
  height: 160px;
  margin-left: -180px;
  padding-top: 75px;
  text-align: center;
  font: Bold 141px/23px Lato;
  letter-spacing: 0;
  color: #929292;
  opacity: 0.12;
}
div.main-container div.central-container div.information-container span.error-message {
  margin: -20px 20px 0 -10px;
  text-align: center;
  font: Bold 20px/38px Lato;
  letter-spacing: 0;
  color: #767676;
  opacity: 1;
}
div.main-container div.central-container div.information-container button.go-back {
  width: 137px;
  height: 35px;
  margin: 20px 30px 0 0;
  padding: 0;
  text-align: center;
  font: Bold 12px/15px Lato;
  text-shadow: 0 3px 6px #00000042;
}
