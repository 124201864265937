.player-menu .inline.fields {
  flex-wrap: wrap;
  justify-content: space-between;
}
.player-menu #play {
  margin-right: 10px;
}
.player-menu .ui.buttons {
  margin-left: 10px;
}
div.waveform.hidden {
  visibility: hidden;
  height: 0;
}
div.waveform div.video-container {
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  padding-top: 25px;
  height: 0;
}
div.waveform div.video-container:hover .video-controls i.icon.huge {
  opacity: 0.7;
  transition-delay: 0s;
}
div.waveform div.video-container .video-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  padding: 0 5%;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
div.waveform div.video-container .video-controls i.icon.huge {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 1.5s;
  color: #fff;
  text-shadow: 3px 0 #000, -3px 0 #000, 0 3px #000, 0 -3px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
}
div.waveform div.video-container .video-controls i.icon.huge:hover {
  transition-delay: 0s;
  opacity: 1;
  transform: scale(1.1);
}
div.waveform div.video-container .video-controls span {
  opacity: 0;
  font-size: 1.5em;
  font-weight: bold;
  position: absolute;
}
div.waveform div.video-container .video-controls span.left {
  left: 0;
}
div.waveform div.video-container .video-controls span.right {
  right: 0;
}
div.waveform div.video-container .video-controls .floatUpAndDisappear {
  animation: floatUpAndDisappear 0.5s;
}
@keyframes floatUpAndDisappear {
  0% {
    opacity: 0;
    transform: translateY(2em);
  }
  25% {
    opacity: 0.7;
    transform: translateY(0);
  }
  50% {
    opacity: 0.7;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2em);
  }
}
div.waveform video.media-element-recording {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
