@media (min-width: 768px) {
  .NewTicketForm .ui.form .fields > .field:first-child {
    padding-left: -0.5em !important;
    margin-left: 0 !important;
  }
}
@media (max-width: 768px) {
  .NewTicketForm .ui.form .fields > .field {
    margin: 0 !important;
    padding: 0.5em 0 !important;
  }
  .NewTicketForm .ui.selection.dropdown {
    margin: 0.5em 0 !important;
  }
  .NewTicketForm .ui.form .fields {
    margin: 0 !important;
  }
}
.dropzone {
  position: relative;
  margin: 0;
  padding: 0.78571429em 1em;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-size: 1em;
  text-align: center;
  color: rgba(191, 191, 191, 0.87);
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  transition: border 0.3s ease;
  cursor: pointer;
}
.dropzone-counter {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.78571429em 1em;
}
.dropzone-files {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.dropzone-file {
  padding: 0.78571429em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: transparent;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropzone-file button {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background: none !important;
  box-shadow: none !important;
}
.dropzone-file-preview {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.dropzone-file-preview img {
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 0.28571429rem;
  object-fit: scale-down;
}
