.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.Review .content {
  text-align: center;
  margin-bottom: 1rem;
}
.Review .content .title {
  font-size: large !important;
  font-weight: 600;
}
.Review .content .ui.segments {
  overflow: auto;
  max-height: 45vh;
}
.Review .ui.label > .icon.review-toggle-angle {
  margin: 0 0 0 0.75rem;
}
.Review .comment-timestamp {
  font-size: 0.85rem;
}
.Review .comment-tools {
  float: right;
  margin-top: -10px;
}
.Review .new-comment {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
}
.Review textarea {
  background-color: #5bc0de43;
  border-radius: 10px;
  border: none;
  width: 80%;
  min-height: 5rem;
  text-align: center;
  padding: 1rem;
}
