.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
@media screen and (max-width: 768px) {
  .UserTasks .ui.table th,
  .UserTasks .ui.table td {
    text-align: center;
    width: 100%;
  }
  .UserTasks .header .header-left .input input {
    border-radius: 25px !important;
    width: 150px;
  }
}
.UserTasks tr:focus {
  background-color: rgba(253, 211, 2, 0.2);
}
.UserTasks .deadline {
  display: flex;
  border: 1px #767676 solid !important;
  padding-left: 1rem;
}
.UserTasks .deadline .icon {
  margin-right: 1rem;
  align-self: center;
  height: auto;
}
.UserTasks .deadline .calendar input {
  border: none !important;
}
.UserTasks .actions {
  display: flex;
}
.UserTasks .statistics {
  display: flex;
  justify-content: space-between;
}
.UserTasks .statistics .resolve {
  max-width: 100px;
  margin: 30px 2px 30px 2px;
}
.UserTasks .statistics .export {
  margin: 30px;
  margin-right: 2px;
}
.UserTasks .statistics .bottom-bar-resolve {
  padding: 7px;
}
.UserTasks .statistics > div {
  display: flex;
  justify-content: flex-end;
}
.UserTasks .statistics > div .progress-bar {
  min-width: 500px;
}
.UserTasks .statistics > div .progress-bar-header {
  display: flex;
  justify-content: space-around;
}
.UserTasks .statistics > div .progress-bar-header .label {
  text-transform: none;
}
@media screen and (max-width: 768px) {
  .UserTasks .statistics > div {
    justify-content: space-between;
  }
  .UserTasks .statistics > div .progress-bar {
    min-width: 0px;
  }
  .UserTasks .statistics > div .progress-bar-header {
    display: flex;
    justify-content: space-around;
  }
}
.UserTasks .datahive-calendar i {
  width: 100px;
}
.UserTasks .datahive-calendar i div {
  color: white;
  text-align: center;
  position: relative;
  top: -5px;
}
.UserTasks .datahive-calendar i .number {
  font-weight: 900;
  font-size: 26px;
  margin-bottom: 10px;
}
.UserTasks .datahive-calendar i .days-left {
  font-size: 14px;
  font-weight: normal;
}
.UserTasks .datahive-calendar i::before {
  font-size: 100px;
  font-weight: normal !important;
  position: relative;
  top: 40px;
  left: 0;
  color: #858585;
  z-index: -1;
}
.UserTasks .add-more-usertasks,
.UserTasks .add-more-evaluatortasks {
  width: 100%;
}
.UserTasks .reset-filters-button {
  margin: auto auto auto 2px;
  color: #A88600;
  cursor: pointer;
}
.UserTasks .filter-button {
  margin: 30px 2px 30px 2px;
}
.UserTasks .filter.icon {
  margin-left: 0.5em !important;
  margin-right: -0.5em !important;
}
.UserTasks .ui.table .ui.segment {
  width: 80px;
  padding: 0.4em 0.7em;
  line-height: 1.21428571em;
  box-shadow: none;
  border: none;
  display: inline-flex;
}
.UserTasks .ui.table .ui.segment i {
  margin: 0.25rem 0 auto 0.85rem;
}
.ui.popup {
  max-width: 500px !important;
}
.filters-menu {
  max-width: 500px !important;
}
.filters-menu .number-range {
  width: 6.16em;
  padding-right: 0 !important;
}
.filters-menu .label-header {
  margin: 0 0 0.28571429rem 0 !important;
}
.filters-menu .submit-button {
  margin-left: auto !important;
}
.filters-menu .close-form-button {
  margin-right: auto !important;
}
.filters-menu .form-buttons {
  margin: 0.5em 0 0.5em 0 !important;
}
.scrollable-popup {
  max-height: 30vh;
  overflow-y: scroll;
}
button.advanced-button {
  width: 50% !important;
  margin: 3px 10%;
  position: relative;
}
button.advanced-button div.label.advanced-filter-label {
  margin: -3px -15px;
  background-color: #034EA2;
  color: #fff;
  min-width: 50px;
}
.ui.popup:before {
  display: none;
}
