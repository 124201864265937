.advanced-filter {
  min-width: max-content !important;
  margin: 0 !important;
}
.advanced-filter .header {
  height: 8%;
  margin: 0 !important;
  min-width: 200px;
}
.advanced-filter .filter-mode {
  padding-top: 2%;
  margin-bottom: 5%;
  width: 100%;
  display: flex;
  font-size: 12px;
  line-height: 1;
}
.advanced-filter .filter-mode .slider {
  margin-left: 10px;
}
.advanced-filter .filter-mode .slider label::after {
  top: -0.1rem !important;
  height: 1rem !important;
  width: 1rem !important;
}
.advanced-filter .filter-mode .slider label::before {
  top: 0.35rem !important;
  width: 2.5rem !important;
}
.advanced-filter .filter-mode i {
  margin-left: 10px;
}
.advanced-filter .widgets-filter-list {
  max-height: 80%;
  overflow-y: scroll;
}
.advanced-filter .widgets-filter-list .fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
}
.advanced-filter .widgets-filter-list .fields span,
.advanced-filter .widgets-filter-list .fields input {
  margin-right: 7px;
}
.advanced-filter .widgets-filter-list .fields > div {
  display: flex;
  align-items: center;
}
.advanced-filter .widgets-filter-list .fields > div .checkbox {
  margin: 0 7px;
  transform: scale(1.25);
}
.advanced-filter .widgets-filter-list::before {
  content: "\00a0\00a0 Exact \00a0\00a0\00a0\00a0 Not \A \00a0 match \00a0 empty";
  white-space: pre-wrap;
  font-size: 10px;
  line-height: 1;
  position: absolute;
  top: 15%;
  right: 60px;
}
.advanced-filter .widgets-filter-list::after {
  content: "Empty";
  font-size: 10px;
  line-height: 1;
  position: absolute;
  top: 16%;
  right: 28px;
}
.advanced-filter .widgets-filter-list.no-scrollbar {
  overflow-y: hidden;
}
.advanced-filter .widgets-filter-list.no-scrollbar::before {
  right: 50px;
}
.advanced-filter .widgets-filter-list.no-scrollbar::after {
  right: 18px;
}
.filters-menu > .filters-form .advanced-button button {
  white-space: nowrap;
  padding: 12px 0 10px !important;
  text-transform: capitalize;
}
