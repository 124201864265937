.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
@font-face {
  font-family: 'samsungsharpsans_bold';
  src: url('/langtasks/fonts/samsungsharpsans_bold.ttf');
}
@font-face {
  font-family: 'samsungone400';
  src: url('/langtasks/fonts/samsungone_400.ttf');
}
@font-face {
  font-family: 'samsungone700';
  src: url('/langtasks/fonts/samsungone_700.ttf');
}
@font-face {
  font-family: 'samsungone800';
  src: url('/langtasks/fonts/samsungone_800.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin200';
  src: url('/langtasks/fonts/samsungoneitaliclatin_200.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin900';
  src: url('/langtasks/fonts/samsungoneitaliclatin_900.ttf');
}
@font-face {
  font-family: 'samsungonekorean200';
  src: url('/langtasks/fonts/samsungonekorean_otf_200.ttf');
}
@font-face {
  font-family: 'samsungonekorean300';
  src: url('/langtasks/fonts/samsungonekorean_otf_300.ttf');
}
@font-face {
  font-family: 'samsungonekorean400';
  src: url('/langtasks/fonts/samsungonekorean_otf_400.ttf');
}
@font-face {
  font-family: 'samsungonekorean500';
  src: url('/langtasks/fonts/samsungonekorean_otf_500.ttf');
}
@font-face {
  font-family: 'samsungonekorean600';
  src: url('/langtasks/fonts/samsungonekorean_otf_600.ttf');
}
@font-face {
  font-family: 'samsungonekorean700';
  src: url('/langtasks/fonts/samsungonekorean_otf_700.ttf');
}
.lander {
  text-align: center;
}
.lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.lander p {
  color: #050505;
}
.gradient {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.green-radio input {
  border: none;
}
.green-radio label::before {
  border-radius: 50% !important;
}
.green-radio label::after {
  background-color: #21ba45;
  border-radius: 50%;
  font-size: 0px !important;
  position: absolute !important;
  top: 3px !important;
  left: 3px !important;
  width: 11px !important;
  height: 11px !important;
}
.FM_SO_400 {
  font-family: 'samsungone400';
}
.FM_SO_700 {
  font-family: 'samsungone700';
}
.FM_SO_800 {
  font-family: 'samsungone800';
}
.FM_IL_200 {
  font-family: 'samsungone_italic_latin200';
}
.FM_IL_900 {
  font-family: 'samsungone_italic_latin900';
}
.FM_SHARP_BOLD {
  font-family: 'samsungsharpsans_bold';
}
.FM_KR_200 {
  font-family: 'samsungonekorean200';
}
.FM_KR_300 {
  font-family: 'samsungonekorean300';
}
.FM_KR_400 {
  font-family: 'samsungonekorean400';
}
.FM_KR_500 {
  font-family: 'samsungonekorean500';
}
.FM_KR_600 {
  font-family: 'samsungonekorean600';
}
.FM_KR_700 {
  font-family: 'samsungonekorean700';
}
.drag-and-drop-selector {
  display: grid !important;
  grid-template-columns: 400px 50px 400px;
  grid-auto-flow: column;
}
.drag-and-drop-selector .filter-choices {
  grid-column: 1;
  grid-row: 1;
  width: 400px !important;
}
.drag-and-drop-selector .choices {
  grid-column: 1;
  grid-row: 2;
}
.drag-and-drop-selector .buttons {
  grid-column: 2;
  grid-row: 2;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
.drag-and-drop-selector .buttons .take-all-buttons {
  margin-left: auto !important;
  margin-right: auto !important;
}
.drag-and-drop-selector .filter-selected {
  grid-column: 3;
  grid-row: 1;
}
.drag-and-drop-selector .selected {
  grid-column: 3;
  grid-row: 2;
}
.drag-and-drop-selector .block-list {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow: auto;
  min-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  height: 100%;
}
.drag-and-drop-selector .block-list li {
  list-style-type: none;
  padding: 2px;
  margin: 3px;
  border: 1px solid rgba(253, 211, 2, 0.2);
  background-color: rgba(253, 211, 2, 0.2);
  border-radius: 0.28571429rem;
}
.drag-and-drop-selector .block-list form {
  padding-left: 15px;
}
.drag-and-drop-selector .small.header {
  margin-bottom: 0;
}
.drag-and-drop-selector .inline.fields {
  margin-bottom: 0 !important;
}
.drag-and-drop-selector .checkboxx input {
  width: 13px !important;
}
.drag-and-drop-selector .const-height {
  max-height: 500px;
}
