.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
div.ui.segment.datahive-segment:not(.inverted).red {
  color: #F44335 !important;
}
div.ui.segment.datahive-segment:not(.inverted).green {
  color: #65A838 !important;
}
div.ui.segment.datahive-segment.inverted.red {
  background-color: #F44335 !important;
  color: #FFFFFF !important;
}
div.ui.segment.datahive-segment.inverted.green {
  background-color: #65A838 !important;
  color: #FFFFFF !important;
}
button.ui.button.datahive-button,
div.field.datahive-button > button.ui.button {
  border-radius: 4px !important;
  font-weight: 600;
}
button.ui.button.datahive-button:not(.basic).red,
div.field.datahive-button > button.ui.button:not(.basic).red {
  background-color: #F44335 !important;
  color: #FFFFFF !important;
}
button.ui.button.datahive-button:not(.basic).green,
div.field.datahive-button > button.ui.button:not(.basic).green {
  background-color: #65A838 !important;
  color: #FFFFFF !important;
}
button.ui.button.datahive-button:not(.basic).yellow,
div.field.datahive-button > button.ui.button:not(.basic).yellow {
  background-color: #FDD302 !important;
  color: #333333 !important;
}
button.ui.button.datahive-button:not(.basic).orange,
div.field.datahive-button > button.ui.button:not(.basic).orange {
  background-color: #FCAC19 !important;
  color: #333333 !important;
}
button.ui.button.datahive-button:not(.basic).dark-grey,
div.field.datahive-button > button.ui.button:not(.basic).dark-grey {
  background-color: #858585 !important;
  color: #FFFFFF !important;
}
button.ui.button.datahive-button:not(.basic).grey,
div.field.datahive-button > button.ui.button:not(.basic).grey {
  background-color: #D8D8D8 !important;
  color: #333333 !important;
}
button.ui.button.datahive-button:not(.basic).light-grey,
div.field.datahive-button > button.ui.button:not(.basic).light-grey {
  background-color: #EBEBEB !important;
  color: #333333 !important;
}
button.ui.button.datahive-button:not(.basic).white2,
div.field.datahive-button > button.ui.button:not(.basic).white2 {
  background-color: #F7F7F7 !important;
  color: #333333 !important;
}
button.ui.button.datahive-button:not(.basic).blue,
div.field.datahive-button > button.ui.button:not(.basic).blue {
  background-color: #3F7CAC !important;
  color: #FFFFFF !important;
}
button.ui.button.datahive-button:not(.basic).light-black,
div.field.datahive-button > button.ui.button:not(.basic).light-black {
  background-color: #525252 !important;
  color: #FFFFFF !important;
}
button.ui.button.datahive-button:not(.basic):hover,
div.field.datahive-button > button.ui.button:not(.basic):hover {
  filter: brightness(95%);
}
button.ui.button.datahive-button.basic.red,
div.field.datahive-button > button.ui.button.basic.red {
  color: #F44335 !important;
  box-shadow: 0 0 0 1px #F44335 inset !important;
}
button.ui.button.datahive-button.basic.green,
div.field.datahive-button > button.ui.button.basic.green {
  color: #65A838 !important;
  box-shadow: 0 0 0 1px #65A838 inset !important;
}
button.ui.button.datahive-button.basic.yellow,
div.field.datahive-button > button.ui.button.basic.yellow {
  color: #FDD302 !important;
  box-shadow: 0 0 0 1px #FDD302 inset !important;
}
button.ui.button.datahive-button.basic.orange,
div.field.datahive-button > button.ui.button.basic.orange {
  color: #FCAC19 !important;
  box-shadow: 0 0 0 1px #FCAC19 inset !important;
}
button.ui.button.datahive-button.basic.dark-grey,
div.field.datahive-button > button.ui.button.basic.dark-grey {
  color: #858585 !important;
  box-shadow: 0 0 0 1px #858585 inset !important;
}
a.datahive-a {
  color: #A88600 !important;
}
a.datahive-icon-button {
  background: none !important;
  color: #333333 !important;
}
div.message.ui.datahive-message {
  background-color: #F7F7F7 !important;
  box-shadow: none !important;
  color: #333333 !important;
}
div.message.ui.datahive-message-error {
  background-color: #F7F7F7 !important;
  box-shadow: none !important;
  color: #F44335 !important;
}
div.ui.fluid.card.datahive-card {
  border-color: #F7F7F7 !important;
  background-color: #F7F7F7 !important;
  border: 0px !important;
  box-shadow: none !important;
}
.datahive-card {
  border: 1px solid #F7F7F7 !important;
  background-color: #F7F7F7 !important;
  box-shadow: none !important;
}
.ui.card > .content,
.ui.cards > .card > .content {
  border: 0px !important;
  box-shadow: none !important;
}
i.icon.datahive-icon.dark-grey {
  color: #858585 !important;
}
i.icon.datahive-icon.light-grey {
  color: #EBEBEB !important;
}
i.icon.datahive-icon.yellow {
  color: #FDD302 !important;
}
i.icon.datahive-icon:hover {
  filter: brightness(90%);
}
* {
  scrollbar-width: thin;
}
body {
  overflow-y: scroll;
  background-color: #FFFFFF;
}
body h1,
body h2,
body h3,
body h4,
body h5 {
  color: #333333;
}
#root {
  padding: 5px;
  padding-top: 60px;
}
.dimmed.dimmable > .ui.modals.dimmer.visible {
  /* Bug for modal windows
  https://stackoverflow.com/questions/49393582/how-to-center-a-modal-in-semantic-ui-react
  */
  display: flex !important;
}
div.modal {
  /* Yet another bug or problem in our templates */
  margin: auto !important;
}
div.modal .header {
  user-select: none !important;
}
