div.message.ui.datahive-message.moduleDetail {
  max-width: 500px;
  text-align: center;
  margin: auto;
}
.DragAndDropSelectorTasks div.block-list.const-height {
  opacity: 0.75;
}
.DragAndDropSelectorTasks div.block-list.const-height li {
  pointer-events: none;
}
