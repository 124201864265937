.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.LinguistsReportList {
  width: 1527px !important;
}
.LinguistsReportList .ui.grid.container {
  width: calc(1527px + 2rem) !important;
}
.LinguistsReportList .ui.button {
  font-weight: 700 !important;
}
.LinguistsReportList .header {
  display: flex;
  justify-content: right;
}
.LinguistsReportList .filters {
  padding: 1em;
  margin: 1em 0;
  background-color: #F7F7F7 !important;
  border-radius: 4px;
  font-weight: 400;
}
.LinguistsReportList .label.evalLabel {
  margin-left: 1em !important;
}
.LinguistsReportList .activityItem {
  display: flex;
  align-items: center;
  padding-bottom: 0.2em;
}
.LinguistsReportList .activityItem .ui.label {
  margin-left: 1em;
  padding: 0.22em 0.46em;
}
.LinguistsReportList .active-title-row {
  background-color: #FDD302 !important;
  color: #333333;
  font-weight: 600;
}
.LinguistsReportList .active-title-row::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #FDD302;
  border-bottom: 0;
  bottom: -15px;
  left: 50%;
  transform: translateX(-15px);
  z-index: 1;
}
.LinguistsReportList .row.content.active {
  border: 1em solid #F7F7F7;
  border-top: 0;
}
.LinguistsReportList .sub-header {
  font-weight: 700;
  background-color: #F7F7F7 !important;
  color: #333333;
}
.LinguistsReportList .ui.table {
  text-align: center;
  border: 1px solid #ebebeb;
}
.LinguistsReportList .ui.table .header {
  font-weight: 700;
  background-color: #F7F7F7;
  color: #333333;
}
.LinguistsReportList .ui.table .body .title:nth-child(odd) {
  background-color: #FFFFFF;
  font-weight: 600;
}
.LinguistsReportList .ui.table .body .title:nth-child(even) {
  background-color: #FFFFFF;
}
.LinguistsReportList .ui.table .body .title:hover {
  background-color: rgba(253, 211, 2, 0.2);
}
.LinguistsReportList .ui.table .body .content {
  display: none !important;
}
.LinguistsReportList .ui.table .body .content .row:hover {
  background-color: rgba(253, 211, 2, 0.2);
}
.LinguistsReportList .ui.table .body .content .other {
  font-weight: 400;
}
.LinguistsReportList .ui.table .body .content.active {
  display: block !important;
}
.LinguistsReportList .ui.table .body .nested {
  margin-bottom: 14px;
}
.LinguistsReportList .ui.table .body .nested .sub-header {
  background-color: #F7F7F7 !important;
}
.LinguistsReportList .ui.table .body .nested:last-of-type {
  margin-bottom: 0;
}
.LinguistsReportList .ui.table .body .nested-title-row {
  background-color: #FFFFFF !important;
  font-weight: 600;
}
.LinguistsReportList .ui.table .body .nested-title-row.even {
  background-color: #ffe661 !important;
}
.LinguistsReportList .ui.table .body .nested-title-row.active {
  background-color: #ffe661 !important;
}
.LinguistsReportList .ui.table .body .nested-title-row.active::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #ffe661;
  border-bottom: 0;
  bottom: -15px;
  left: 50%;
  transform: translateX(-15px);
  z-index: 1;
}
.LinguistsReportList .ui.table .body .nested-sub-header {
  font-weight: 700;
  background-color: #F7F7F7 !important;
}
.LinguistsReportList .ui.table .body .nested-content {
  display: none !important;
  border: 1em solid #F7F7F7;
  border-top: 0;
}
.LinguistsReportList .ui.table .body .nested-content.active {
  display: block !important;
}
.LinguistsReportList .not-submitted,
.LinguistsReportList .ui.label.not-accepted {
  color: #F44335 !important;
  background: none;
  border-color: #F44335;
  border-width: 1px;
  border-radius: 4px;
}
.LinguistsReportList .ui.label.pending {
  background: none;
  color: #F44335 !important;
  border-color: #F44335;
  border-width: 1px;
  border-radius: 4px;
}
.LinguistsReportList .ui.label.accepted,
.LinguistsReportList .manually-accepted {
  color: #65A838 !important;
  background: none;
  border-color: #65A838;
  border-width: 1px;
  border-radius: 4px;
}
.LinguistsReportList .edit,
.LinguistsReportList .edit:focus {
  background-color: #FDD302;
  color: #FFFFFF;
}
.LinguistsReportList .edit:hover {
  background-color: #e4be02;
}
.LinguistsReportList .collapsed-header {
  max-height: 0;
  overflow: hidden;
  padding: 0 !important;
  -webkit-transition: height 1s;
  /* Safari */
  transition: height 1s, max-height 1s, padding 1s;
}
.LinguistsReportList .un-collapsed-header {
  max-height: 300px;
  -webkit-transition: height 1s;
  /* Safari */
  transition: height 1s, max-height 1s;
}
.LinguistsReportList .flipped {
  transform: rotate(180deg) !important;
  -webkit-transform: scale(1, 1);
  -webkit-transition: transform 1s;
  /* Safari */
  transition: transform 1s;
}
.LinguistsReportList .not-flipped {
  transform: rotate(0deg) !important;
  -webkit-transform: scale(1, 1);
  -webkit-transition: transform 1s;
  /* Safari */
  transition: transform 1s;
}
.LinguistsReportList .colGroupByProject {
  font-size: 0.9em;
}
.LinguistsReportList .ui.slider.checkbox {
  margin-left: 10px;
}
.LinguistsReportList .ui.slider.checkbox label:after {
  top: 0 !important;
  height: 1rem !important;
  width: 1rem !important;
}
.LinguistsReportList .ui.slider.checkbox label:before {
  top: 0.5rem !important;
  width: 2.5rem !important;
}
.LinguistsReportList .ui.checkbox {
  min-height: 14px !important;
}
.LinguistsReportList .ui.slider.checkbox label {
  padding-left: 3.25rem;
}
.LinguistsReportList .ui.left.icon.input {
  width: 100% !important;
}
.LinguistsReportList .filterModes {
  display: flex;
  justify-content: space-around;
}
.LinguistsReportList span.reset-filters-button {
  color: #A88600;
  cursor: pointer;
  vertical-align: bottom;
}
.LinguistsReportList .submit-button {
  min-width: 8.18rem !important;
}
.LinguistsReportList .no-padding-column {
  padding: 0 !important;
}
.hours,
.calculated-hours {
  font-weight: 700;
}
.acceptable {
  color: #65A838;
}
.nonacceptable {
  color: #F44335;
}
.wholeReportHistory {
  max-height: 30vh;
  overflow-y: scroll;
}
.hours-input {
  width: 7em;
}
.sortable-header {
  cursor: pointer;
}
.ReportExport .header {
  text-align: center !important;
  font-weight: 700 !important;
  background-color: #FDD302 !important;
  color: #333333 !important;
  border-bottom: none !important;
}
.native-icon {
  color: #FDD302;
}
.non-native-icon {
  color: #D8D8D8;
  opacity: 0.9;
}
.task-report-row.active {
  background-color: #ffee96 !important;
}
.session-summary.active {
  background-color: #ffee96 !important;
}
