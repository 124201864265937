.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.TaskListItem:hover {
  background-color: rgba(253, 211, 2, 0.2);
}
.TaskListItem td {
  color: #333333;
}
.TaskListItem td.actions {
  text-align: center !important;
}
.TaskListItem .ui.icon.button {
  background-color: #FFFFFF;
  width: 38.5px;
  color: #333333;
}
.TaskListItem .ui.icon.button .star {
  color: #333333 !important;
}
.TaskListItem .ui.table {
  max-width: 50px;
}
.TaskListItem .green-icon {
  color: #65A838;
}
.TaskListItem .red-icon {
  color: #F44335;
}
.TaskListItem a {
  color: #858585;
}
.TaskListItem a:hover {
  color: #858585;
}
.TaskListItem .ui.icon.button.favorites {
  margin: 0;
  padding: 0;
  width: auto;
  background-color: transparent;
}
.TaskListItem .ui.icon.button.favorites:hover {
  filter: brightness(90%);
}
.TaskListItem .favorites-cell {
  cursor: pointer;
}
.TaskListItem .progress-middle {
  margin: auto !important;
}
.TaskListItem .evaluationItem .ui.circular.label {
  margin-left: 1em;
}
.pin-color-item {
  padding: 0 !important;
  background: transparent !important;
}
