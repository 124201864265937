.tab-list-item {
  transform: translateY(1px);
  padding: 0.92857143em 1.42857143em;
  position: relative;
  z-index: 0;
  color: black;
}
.tab-list-item:hover {
  color: black;
}
.tab-list-item:active,
.tab-list-item:focus {
  text-decoration: none;
}
.tab-list-item * {
  pointer-events: none;
}
.tab-list-active {
  color: black;
  border: 1px solid #d4d4d5;
  border-bottom: none;
  border-radius: 0.28571429rem 0.28571429rem 0 0;
  position: relative;
  text-decoration: none;
  font-weight: 600;
}
.tab-list-active::after {
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  z-index: 20;
  left: 0;
  background-color: white;
}
.tab-list {
  display: inline-block;
}
