.hours-input {
  display: flex;
  justify-content: center;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.hours-input .input {
  max-width: 25%;
}
.hours-input input::-webkit-outer-spin-button,
.hours-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hours-input input[type="number"] {
  -moz-appearance: textfield;
}
.right-input input {
  text-align: right !important;
}
.arrows {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
