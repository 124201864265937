.annotation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.annotation-container .disabled.field {
  opacity: 1 !important;
}
.annotation-container .disabled.field * {
  opacity: inherit !important;
}
.selectedRegion {
  background: rgba(66, 68, 90, 0.15);
  border-radius: 3px;
  border-color: transparent !important;
}
.selectedRegion + .item {
  border-color: transparent !important;
}
