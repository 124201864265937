.ColorPickerContainer {
  width: max-content;
  margin: auto;
}
.ColorPickerContainer .color-input {
  width: 10em;
  border-radius: 0.28571429rem;
}
.ColorPickerContainer .color-input::before {
  content: "";
  width: 25px;
}
