.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.TreeSelect .ui.dropdown .menu .isNode {
  font-weight: bold;
  background: rgba(0, 0, 0, 0.1);
}
.TreeSelect .ui.compact.icon.button.tiniest {
  padding: 0.28928571em 0.28928571em 0.28928571em;
  margin-left: 0.28928571em;
}
.TreeSelect .menu.transition.visible {
  border-radius: 0 !important;
}
.TreeSelect .full-width {
  width: 100%;
}
.TreeSelect .node-title {
  width: 100%;
  display: inline-block;
  border-radius: 4px;
}
.TreeSelect .node-title:hover {
  background-color: rgba(34, 36, 38, 0.15);
}
.TreeSelect .ui.list > .item {
  display: flex;
}
.TreeSelect .dropdown-content {
  border-style: solid;
  border-color: rgba(34, 36, 38, 0.15);
  border-radius: 0 0 5px 5px;
  border-width: 1px;
  border-top-width: 0px;
  overflow: scroll;
}
.TreeSelect .open-dropdown {
  border-radius: 5px 5px 0 0 !important;
}
.TreeSelect div.content > div.node-title {
  padding: 0;
}
.TreeSelect .content > div {
  border: none !important;
}
