.ui.table.AnswerAdminListByTask {
  font-size: 0.8em;
}
.AnswerAdminListByTask .ui.checkbox {
  margin: 5px;
  vertical-align: middle;
}
.AnswerAdminListByTaskContainer {
  margin: 1rem 1rem 45px 1rem;
}
.AnswerAdminListByTaskContainer .title {
  margin: 1rem;
  font-size: large;
}
.AnswerAdminListByTaskContainer .filters {
  display: flex;
  justify-content: flex-start;
}
.AnswerAdminListByTaskContainer .filters > * {
  margin-right: 10px;
}
.AnswerAdminListByTaskContainer .ui.celled.table tr td:first-child,
.AnswerAdminListByTaskContainer .ui.celled.table tr th:first-child {
  margin: 0;
  text-align: center;
}
.AnswerAdminListByTaskContainer .ui.checkbox.checkboxFilters {
  display: flex;
  align-items: center;
}
.DeleteAllModal {
  max-height: 400px;
  overflow: auto;
}
