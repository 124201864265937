.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
  .Login div > ul {
    padding: 0;
  }
  .Login div > ul > li {
    list-style: none;
  }
}
.ui.form .field > label {
  color: #333333 !important;
}
.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-row .reset-btn {
  transform: translate(4px, -35px);
  border-radius: 0.28571429rem;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1em;
  font-size: 12px;
}
