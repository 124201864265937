.container.SubmitButtonWidget {
  padding-top: 25px !important;
  display: flex !important;
  justify-content: flex-end;
}
.container.SubmitButtonWidget .ui.button {
  margin-right: 10px;
}
.container.SubmitButtonWidget .ui.button i.spinner.loading.icon {
  margin: 0;
}
