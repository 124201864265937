.task-imprint .header {
  font-size: 16px !important;
  margin-bottom: 0px !important;
}
.task-imprint__block {
  display: flex;
  justify-content: space-between;
}
.task-imprint__block .label {
  margin-left: 2rem;
  margin-right: 1rem;
}
.task-imprint__date {
  display: block;
  text-align: right !important;
}
