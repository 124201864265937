.SemanticColorSelectorButton {
  padding: 0px !important;
  width: 40px !important;
  height: 40px !important;
}
.SemanticColorSelectorButton.mini {
  width: 10px !important;
  height: 10px !important;
}
.SemanticColorSelectorButton.tiny {
  width: 20px !important;
  height: 20px !important;
}
.SemanticColorSelectorButton.small {
  width: 30px !important;
  height: 30px !important;
}
.SemanticColorSelectorButton.medium {
  width: 40px !important;
  height: 40px !important;
}
.SemanticColorSelectorButton.large {
  width: 50px !important;
  height: 50px !important;
}
.SemanticColorSelectorButton.big {
  width: 60px !important;
  height: 60px !important;
}
.SemanticColorSelectorButton.huge {
  width: 70px !important;
  height: 70px !important;
}
.SemanticColorSelectorButton.massive {
  width: 80px !important;
  height: 80px !important;
}
