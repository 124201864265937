.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.ui.menu .header img {
  height: 50px;
  width: 100%;
}
.top-menu.ui.menu {
  background-color: #F7F7F7;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 50px;
  width: 100%;
  z-index: 1901 !important;
}
.top-menu.ui.menu .notifications .bell {
  margin-right: 0px;
}
.top-menu.ui.menu .notifications:focus {
  text-decoration: none !important;
}
.top-menu.ui.menu .notifications .notification-counter {
  margin-top: 5px;
  background-color: #F44335;
  color: #fff;
}
.top-menu.ui.menu .ui.labeled.icon.button {
  padding-left: 1em !important;
}
.top-menu.ui.menu a,
.top-menu.ui.menu .dropdown {
  color: #333333;
}
.top-menu.ui.menu .menu.transition.visible {
  background-color: #F7F7F7;
}
.top-menu.ui.menu .menu.transition.visible .item {
  color: #333333 !important;
}
.top-menu.ui.menu a:hover,
.top-menu.ui.menu .dropdown:hover {
  color: #666666 !important;
}
.top-menu.ui.menu .ui.item.button.login {
  background-color: #ffffff;
  color: #4d4d4d;
  max-height: 75% !important;
  align-self: center;
  border-radius: 0.3em !important;
  margin: 0.5em;
  padding: 0 0.8em !important;
}
.top-menu.ui.menu .item {
  padding: 0 0.5em;
  height: 100%;
  font-weight: 600;
}
@media (max-width: 890px) {
  .top-menu.ui.menu .item {
    padding: 0.6em 0.8em;
  }
}
.top-menu.ui.menu .active.item,
.top-menu.ui.menu .ui.dropdown .menu > .item:hover {
  color: #333333 !important;
  font-weight: 600;
}
.top-menu.ui.menu .header {
  font-size: large;
  margin-bottom: 2px;
  font-weight: 600;
  color: #333333;
}
.top-menu.ui.menu .hamburger {
  transition: transform 0.4s ease 0.2s;
  background-color: #F7F7F7;
  width: 100%;
  position: absolute;
  top: 100%;
  overflow: hidden;
  height: auto;
  transform: scaleY(0);
  transform-origin: top;
  will-change: transform;
}
.top-menu.ui.menu .hamburger .header {
  padding: 0.3em 0.8em;
  font-size: medium;
}
.top-menu.ui.menu .hamburger * {
  opacity: 0;
  transition: opacity 0.2s ease;
}
.top-menu.ui.menu .hamburger.openHamburger {
  transition: transform 0.4s ease;
  transform: scaleY(1);
}
.top-menu.ui.menu .hamburger.openHamburger * {
  opacity: 1;
  transition: opacity 0.2s ease 0.4s;
}
.top-menu.ui.menu .sidebar {
  transition: 0.5s ease;
}
