.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.PaginationCompact .ui.button.prev-next {
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0);
  width: 3rem;
  vertical-align: 20%;
  margin: 0;
  padding: 0;
}
.PaginationCompact .ui.button.prev-next.hidden {
  visibility: hidden;
}
.PaginationCompact .ui.button.prev-next:hover,
.PaginationCompact .ui.button.prev-next:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}
.PaginationCompact .last-page {
  height: 2.5rem;
  margin: 0;
}
.PaginationCompact .last-page i.angle.double.right.icon {
  background-color: #858585;
  color: #F7F7F7;
}
.PaginationCompact input {
  width: 7.5rem;
  height: 2.5rem;
  text-align: center !important;
}
