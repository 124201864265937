.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
@font-face {
  font-family: 'samsungsharpsans_bold';
  src: url('/langtasks/fonts/samsungsharpsans_bold.ttf');
}
@font-face {
  font-family: 'samsungone400';
  src: url('/langtasks/fonts/samsungone_400.ttf');
}
@font-face {
  font-family: 'samsungone700';
  src: url('/langtasks/fonts/samsungone_700.ttf');
}
@font-face {
  font-family: 'samsungone800';
  src: url('/langtasks/fonts/samsungone_800.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin200';
  src: url('/langtasks/fonts/samsungoneitaliclatin_200.ttf');
}
@font-face {
  font-family: 'samsungone_italic_latin900';
  src: url('/langtasks/fonts/samsungoneitaliclatin_900.ttf');
}
@font-face {
  font-family: 'samsungonekorean200';
  src: url('/langtasks/fonts/samsungonekorean_otf_200.ttf');
}
@font-face {
  font-family: 'samsungonekorean300';
  src: url('/langtasks/fonts/samsungonekorean_otf_300.ttf');
}
@font-face {
  font-family: 'samsungonekorean400';
  src: url('/langtasks/fonts/samsungonekorean_otf_400.ttf');
}
@font-face {
  font-family: 'samsungonekorean500';
  src: url('/langtasks/fonts/samsungonekorean_otf_500.ttf');
}
@font-face {
  font-family: 'samsungonekorean600';
  src: url('/langtasks/fonts/samsungonekorean_otf_600.ttf');
}
@font-face {
  font-family: 'samsungonekorean700';
  src: url('/langtasks/fonts/samsungonekorean_otf_700.ttf');
}
.lander {
  text-align: center;
}
.lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.lander p {
  color: #050505;
}
.gradient {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.green-radio input {
  border: none;
}
.green-radio label::before {
  border-radius: 50% !important;
}
.green-radio label::after {
  background-color: #21ba45;
  border-radius: 50%;
  font-size: 0px !important;
  position: absolute !important;
  top: 3px !important;
  left: 3px !important;
  width: 11px !important;
  height: 11px !important;
}
.FM_SO_400 {
  font-family: 'samsungone400';
}
.FM_SO_700 {
  font-family: 'samsungone700';
}
.FM_SO_800 {
  font-family: 'samsungone800';
}
.FM_IL_200 {
  font-family: 'samsungone_italic_latin200';
}
.FM_IL_900 {
  font-family: 'samsungone_italic_latin900';
}
.FM_SHARP_BOLD {
  font-family: 'samsungsharpsans_bold';
}
.FM_KR_200 {
  font-family: 'samsungonekorean200';
}
.FM_KR_300 {
  font-family: 'samsungonekorean300';
}
.FM_KR_400 {
  font-family: 'samsungonekorean400';
}
.FM_KR_500 {
  font-family: 'samsungonekorean500';
}
.FM_KR_600 {
  font-family: 'samsungonekorean600';
}
.FM_KR_700 {
  font-family: 'samsungonekorean700';
}
.ui.segments.users-list {
  border: none;
  /* Tooltip container */
  /* Tooltip text */
  /* Tooltip arrow */
  /* Show the tooltip text when you mouse over the tooltip container */
}
.ui.segments.users-list .ui.sortable.table thead th {
  background-color: #EBEBEB !important;
  border-color: #D8D8D8 !important;
  color: #333333 !important;
}
.ui.segments.users-list .ui.segments {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.ui.segments.users-list .ui.segments a {
  color: #858585;
}
.ui.segments.users-list .green-icon {
  color: #65A838;
}
.ui.segments.users-list .red-icon {
  color: #F44335;
}
.ui.segments.users-list .orange-icon {
  color: #FCAC19;
}
.ui.segments.users-list .export-segment {
  max-width: 250px;
}
.ui.segments.users-list .filters {
  margin: 1px !important;
  padding: 1em;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75) !important;
  background-color: rgba(221, 219, 203, 0.15) !important;
}
.ui.segments.users-list .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}
.ui.segments.users-list .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
.ui.segments.users-list .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.ui.segments.users-list .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.ui.segments.users-list .unavailable {
  background-color: rgba(0, 0, 0, 0.04);
  color: #aaaaaa !important;
}
.ui.segments.users-list .unavailable a {
  color: #858585;
  text-decoration: none;
}
.ui.segments.users-list .add-many-users {
  height: 50px;
  padding: 0 11px;
}
.ui.segments.users-list .add-many-users .add-many-users-link {
  float: right;
}
div.language-filter.react-dropdown-tree-select {
  clear: both;
  margin: 0 0 1em;
}
div.language-filter.react-dropdown-tree-select .dropdown {
  width: 100%;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger {
  padding: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  width: 100%;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger.arrow.bottom:after,
div.language-filter.react-dropdown-tree-select a.dropdown-trigger.arrow.top:after {
  content: '\23F7';
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.21428571em;
  position: absolute;
  top: 0.78571429em;
  right: 1em;
  opacity: 0.9;
  font-size: 0.85714286em;
  margin-right: 0;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger .tag-list > .tag-item.pill {
  padding: 0.1em 0.1em;
  margin: 0.25rem 0.25rem 0.25rem 0.25rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
  font-size: 1em;
  background-color: #e8e8e8;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  border-radius: 0.28571429rem;
  border: 0 solid transparent;
  text-align: left;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger .tag-list > .tag-item.pill span {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background-color: #e8e8e8;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger .tag-list > .tag-item.pill span > button.tag-remove {
  color: transparent;
  height: 1em;
  margin-left: 0.5em;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger .tag-list > .tag-item.pill span > button.tag-remove:before {
  color: rgba(0, 0, 0, 0.6);
  content: '\00D7';
  height: 1em;
  font-size: 1.8em;
  font-weight: 900;
  opacity: 0.5;
  vertical-align: middle;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger .tag-list > .tag-item {
  margin: 0;
}
div.language-filter.react-dropdown-tree-select a.dropdown-trigger .tag-list > .tag-item input {
  border: 0;
}
div.language-filter.react-dropdown-tree-select a[aria-expanded="true"].dropdown-trigger {
  border-bottom: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
div.language-filter.react-dropdown-tree-select div.dropdown-content {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: 0;
  box-shadow: none;
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  min-width: 100%;
  width: 100%;
  padding: 0;
  top: calc(100% - 4px);
}
div.language-filter.react-dropdown-tree-select div.dropdown-content ul.root .language-leaf label > input {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
div.language-filter.react-dropdown-tree-select div.dropdown-content ul.root .language-leaf i.toggle {
  margin-left: 2px;
}
@media only screen and (min-width: 992px) {
  div.language-filter.react-dropdown-tree-select div.dropdown-content ul.root .infinite-scroll-component {
    max-height: 16.02857143rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
