.timer-container {
  max-width: 500px;
  margin: 24px auto;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.26);
}
.timer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timer-group {
  display: flex;
  gap: 16px;
  align-items: center;
}
.timer-display {
  margin: 0;
}
.category-container {
  min-width: 150px;
}
.category-container h4 {
  margin: 0;
}
