.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.add-many-user {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 8rem;
  width: 50%;
}
.add-many-user .tabs {
  width: 100%;
}
.add-many-user .ui.form {
  margin-top: 1em;
}
.add-many-user .field label {
  margin-top: 1.5em !important;
  font-size: 110% !important;
}
.add-many-user .fields {
  display: flex;
  justify-content: space-around;
}
.add-many-user .fields .field {
  min-width: 20% !important;
}
.add-many-user .ui.three.column {
  width: 50%;
}
.add-many-user .ui[class*="three column"].grid > .row > .column .const-height {
  max-height: 200px;
}
.add-many-user .ui.icon.button {
  background-color: #FFFFFF;
  width: 38.5px;
  color: #333333;
}
.add-many-user .buttonsMainSlug {
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
}
.report-title {
  padding: 5px;
}
