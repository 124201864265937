.color-1 {
  background-color: #050505;
}
.color-2 {
  background-color: #6b0504;
}
.color-3 {
  background-color: #dd6e42;
}
.color-4 {
  background-color: #f1ab86;
}
.color-5 {
  background-color: #fed766;
}
.color-6 {
  background-color: #fffcf9;
}
.color-7 {
  background-color: #dddbcb;
}
.color-8 {
  background-color: #abd1b5;
}
.color-9 {
  background-color: #06d6a0;
}
.color-10 {
  background-color: #034EA2;
}
.color-11 {
  background-color: #19647e;
}
.color-12 {
  background-color: #86a5d9;
}
.color-13 {
  background-color: #9649cb;
}
.color-14 {
  background-color: #ef476f;
}
.color-15 {
  background-color: #832232;
}
.color-16 {
  background-color: #a50104;
}
.DescriptionContainer {
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 5px;
}
.DescriptionContainer .header {
  padding-bottom: 10px;
}
.DescriptionContainer .button {
  /* Without <table> isn't squared as expected */
  padding: 0.7rem;
  padding-right: 0.35rem;
}
.DescriptionContainer .link {
  color: #fffcf9;
  background-color: #a5673f;
}
.DescriptionContainer .deadline {
  margin-top: 0.5rem;
  font-size: large;
  text-align: center;
}
.DescriptionContainer .deadline.red {
  color: #F44335;
}
.DescriptionContainer .deadline.green {
  color: #65A838;
}
.DescriptionContainer .answer-limit {
  margin-top: 1rem;
  font-size: large;
  text-align: center;
}
.DescriptionContainer #resign {
  color: #fffcf9;
  background-color: #F44335;
  padding: 0.7rem;
  margin-right: 0;
}
.DescriptionContainer .favorites {
  position: relative;
  margin: 0;
  margin-left: 0.35rem !important;
  padding: 0 !important;
  width: auto;
  background: transparent !important;
  cursor: pointer;
}
.DescriptionContainer .favorites i {
  font-size: 18px;
}
.DescriptionContainer .favorites:hover {
  filter: brightness(90%);
}
.pin-popup {
  z-index: 2000 !important;
}
